
































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    currency: {
      type: String,
      required: false,
      default: "PLN",
    },
    participant: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const model = reactive({
      feeId:
        props.participant.participantRegistrationFeeTerm.registrationFeeTerm.id,
      registrationCode:
        props.participant.participantRegistrationFeeTerm.registrationFeeTerm
          .registrationFee.registrationCode || null,
      companion: props.participant.companion || null,
    });

    const state = reactive({
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      fees: [],
    });

    const fetchFees = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/registration-fee/group`)
        .then(({ data }) => {
          state.fees = data.map((registrationFeeGroup: any) => ({
            ...registrationFeeGroup,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.fees = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchFees);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.participantAlreadyExists")}`;
        case 410:
          return `${root.$tc("layout.errors.incorrectRegistrationCode")}`;
        case 411:
          return `${root.$tc("layout.errors.companionIsRequired")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      const data = {
        registrationCode: model.registrationCode,
        companion: model.companion,
      };

      axiosInstance
        .put(
          `participant/${root.$route.params.ptid}/registration-fee-term/${model.feeId}`,
          data,
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.success = true;
          state.error = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "event.participant.editInfo.participantUpdateSuccessfull"
            ),
          });
          emit("fetch");
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { model, state, onSubmit };
  },
});
